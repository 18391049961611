.appSlider {
  margin-left: 5%;
}

.appSlider__section {
  border: solid 2px rgb(71, 71, 71, 0.3);
  padding: 1.5rem;
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  height: auto !important;
  background-color: white;
}

.appSlider__iconRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.appSlider__icon {
  width: 35px;
  height: auto;
  margin-right: 30px;
  margin-bottom: 1rem;
}

.appSlider__title {
  font-weight: 600;
  color: #616161;
  margin-bottom: 10px;
}