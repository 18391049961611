/* Test commit */
.connect {
  font-size: 20px;
  height: 20rem;
  width: 20rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  margin: 3%;
}

.connect__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.connect--blue {
  /* background-color: rgb(106, 185, 255, 0.5); */
  border: solid 2px #333;
}

.connect--red {
  /* background-color: rgb(247, 64, 64, 0.5); */
  border: solid 2px #333;
}

.connect--green {
  /* background-color: rgb(129, 211, 75, 0.5); */
  border: solid 2px #333;
}

.connect--grey {
  background-color: rgba(224, 224, 223, 0.5);
}

.connect a {
  text-decoration: underline;
}
