.pricing {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.pricing__title {
  font-size: 30px;
  font-weight: 600;
}
