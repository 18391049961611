.register__wrapper {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background: url("../../media/User\ Auth/Background-min.svg");
  background-size: cover;
}

.register__header {
  text-align: center;
  margin-bottom: 5rem;
}
