/* 
============================================================ 
Reset Styles
============================================================ 
*/
html,
body,
*::before,
*::after,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow: visible;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* 
============================================================ 
Variables
============================================================ 
*/
:root {
  --darkBlue: 103, 73, 190;
  --darkGray: 97, 97, 97;
  --btnBlue: 103, 73, 190;
  --btnDarkBlue: 103, 73, 150;
  --btnOrange: 227, 86, 47;
  --paddingSmall: 5rem;
  --paddingLarge: 10rem;
  --shadow: 0px 4px 22px 2px rgba(var(--darkBlue), 0.1);
}

/* 
============================================================ 
Top-Level Stuff
============================================================ 
*/
@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");

html,
body {
  font-size: 60.5%;
  margin: 0;
  font-family: "Arimo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
============================================================ 
Typography
============================================================ 
*/

a {
  text-decoration: none;
  color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--darkBlue);
}

h1 {
  font-size: 6rem;
  font-size: clamp(3.6rem, 10vw, 6rem);
  font-weight: 800;
  line-height: 1.25;
  padding-bottom: 2rem;
  color: #2d2d2d;
}

@media only screen and (max-width: 1400px) {
  h1 {
    font-size: 4.4rem;
  }
}

@media only screen and (max-width: 760px) {
  h1 {
    font-size: 3.6rem;
  }
}

h2 {
  font-size: 4.8rem;
  font-size: clamp(3.8rem, 10vw, 4.8rem);
  font-weight: 400;
  line-height: 1.25;
  padding-bottom: 3rem;
}

@media only screen and (max-width: 1400px) {
  h2 {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 1000px) {
  h2 {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: 2.4rem;
  }
}

h3 {
  font-size: 3.5rem;
  font-size: clamp(2.5rem, 10vw, 3.5rem);
  font-weight: 400;
  line-height: 1.25;
  padding-bottom: 2rem;
}

@media only screen and (max-width: 1400px) {
  h3 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 1000px) {
  h3 {
    font-size: 2.25rem;
  }
}

@media only screen and (max-width: 600px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: 2.4rem;
  font-size: clamp(1.4rem, 10vw, 2.4rem);
  font-weight: 400;
  line-height: 1.25;
}

@media only screen and (max-width: 1400px) {
  h4 {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 760px) {
  h4 {
    font-size: 1.2rem;
  }
}

h5 {
  font-size: 2.4rem;
  font-size: clamp(1.4rem, 10vw, 2.4rem);
  font-weight: 800;
  line-height: 1.25;
}

p,
li {
  font-size: 2rem;
  font-size: clamp(1rem, 10vw, 2rem);
  line-height: 1.7;
  color: var(--darkBlue);
  font-weight: 100;
}

.sub {
  font-size: 1.8rem;
  font-size: clamp(1.4rem, 10vw, 1.8rem);
  line-height: 1.7;
  margin-top: 0.5rem;
  color: var(--darkBlue);
}

@media only screen and (max-width: 760px) {
  .sub {
    font-size: 1.4rem;
  }
}

.descr {
  max-width: 60ch;
}

button {
  font-size: 1.8rem;
  font-size: clamp(1.2rem, 10vw, 1.8rem);
  font-weight: 600;
}

@media only screen and (max-width: 760px) {
  button {
    font-size: 1.2rem !important;
  }
}

hr {
  background-color: white;
  height: 0.2rem;
  width: 12rem;
  border: none;
  margin-left: 0;
  margin-top: 0;
}

.hr--left {
  background-color: white;
  width: 10rem;
  border: none;
  text-align: left;
  margin-right: auto;
  margin-bottom: 1rem;
}

/* 
============================================================ 
Padding
============================================================ 
*/
.padding--small {
  padding: var(--paddingSmall);
}

.padding--large {
  padding: var(--paddingLarge);
}

.padding--large {
  padding: var(--paddingLarge);
}

.margin--large {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 8%;
  margin-right: 8%;
}

@media only screen and (max-width: 760px) {
  .margin--large {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

/* 
============================================================ 
Buttons
============================================================ 
*/
.btn {
  padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  border-radius: 6px;
  border: none;
  color: white;
  width: max-content;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

.btn--orange {
  background-color: #ffffff00;
  border: rgb(var(--btnOrange)) solid 2px;
  transition: all 0.3s;
  color: #2d2d2d;
}

.btn--orange:hover {
  opacity: 0.7;
  border: rgb(var(--btnOrange)) solid 2px;
  color: #2d2d2d;
}

.btn--blue {
  border: rgb(var(--btnBlue)) solid 2px;
  background-color: rgb(var(--btnBlue));
  transition: all 0.3s;
  color: white;
}

.btn--blue:hover {
  border: rgb(var(--btnDarkBlue)) solid 2px;
  background-color: rgb(var(--btnDarkBlue));
  color: white;
}

/* 
============================================================ 
Layouts
============================================================ 
*/
.card {
  border-radius: 20px;
  padding: var(--paddingLarge);
  background-color: rgba(255, 255, 255, 0.945);
  box-shadow: var(--shadow);
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
}

@media only screen and (max-width: 760px) {
  .card {
    padding: 50px;
  }
}

.home {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.27), rgba(0, 0, 0, 0.27));
  background: url("./media/Desktop/DesktopBackground.png");
  background-size: cover;  */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.856), rgba(0, 0, 0, 0.897));
}

.home__video {
  position: absolute;
  top: 0;
  left: -30%;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(5px);
}

/* .hero {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.27), rgba(0, 0, 0, 0.27)),
    radial-gradient(
      137.86% 257.76% at 73.05% 12.97%,
      rgba(70, 80, 172, 0.696) 0%,
      rgba(141, 133, 55, 0.208) 100%
    );
} */

.hero--card {
  max-height: 50vh;
  max-width: 70vw;
  padding: 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: -15rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.hero--card * {
  max-width: 120ch;
}

.hero__btn {
  margin-right: 3rem;
}

.hero--card h1,
h4 {
  color: var(--darkBlue);
}

.icons__row {
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  width: 60rem;
}

.btn__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 15rem;
  width: 38rem;
}

.card--full {
  width: 80vw;
  max-height: max-content;
  padding: 6%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.card--full * {
  text-align: left;
}

/* 
============================================================ 
Utilities
============================================================ 
*/
.centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: left;
}

.rounded {
  clip-path: circle(50% at 50% 50%);
}

/* 
============================================================ 
Shadows
============================================================ 
*/
.drop-shadow {
  box-shadow: var(--shadow);
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
}

/* 
============================================================ 
Desktop UI
============================================================ 
*/
.desktop {
  background-attachment: fixed;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  background: url("./media/Desktop/DesktopBackground.png");
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 10vh 5vh;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "desktop__title"
    "desktop__taskbar"
    "desktop__footer";
}

.wrapper {
  background-image: url("./media/Vision/background-dark.webp");
  background-size: 100%;
}

/* Makes the background blurry, while avoiding overflow issues and using backdrop */
.desktop--blur::before {
  content: "";
  /* animation: desktop--blur-ai 0.4s 0.1s both; */
}

/* Removes the white border from the blur effect */
.desktop::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("./media/Desktop/DesktopBackground.png");
  background-size: cover;
  background-position: center;
}

@keyframes desktop--blur-ai {
  0% {
    filter: blur(0px) brightness(100%);
  }
  100% {
    filter: blur(8px) brightness(90%);
  }
}

.desktop__title {
  grid-area: desktop__title;
  color: white;
  z-index: 10;
  padding: 5rem;
  padding-bottom: 0;
  font-size: 20rem;
  cursor: default;
}

.desktop__title--active {
  animation: desktop__title--fade-ai 0.3s 0.2s both;
}

@keyframes desktop__title--fade-ai {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.desktop__title--inactive {
  animation: desktop__title--fade-ao 0.3s 0s both;
}

@keyframes desktop__title--fade-ao {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.desktop__title-drag {
  display: flex;
  flex-direction: row;
}

.desktop__title-drag h1 {
  color: white;
}

.desktop__title-drag:nth-of-type(2) {
  margin-top: -2.5rem;
}

.desktop__loader {
  background-color: #000;
  position: absolute;
  z-index: 10;
}

.desktop__loader--animate {
  animation: desktop__loader 2s 1.5s forwards;
}

@keyframes desktop__loader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.desktop__taskbar {
  grid-area: desktop__taskbar;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 90;
  margin-bottom: -1rem;
}

.desktop__footer {
  grid-area: desktop__footer;
  z-index: 100;
  height: auto;
}

.desktop__icons {
  background-color: #00000030;
  border-radius: 12px;
}

.desktop__icons--item {
  margin: 3%;
  margin-left: 5rem;
  margin-right: 5rem;
  transition: all 0.25s ease-out;
  cursor: pointer;
  max-width: 4rem;
  height: auto;
  z-index: 20;
}

.desktop__icons--item::after {
  content: "";
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

.desktop__icons--item:hover {
  transform: translateY(-0.8rem);
}

/* 
============================================================ 
AppStore/AppDrawer
============================================================ 
*/
.app-drawer--active {
  animation: app-drawer-ai 1s 0s ease-in-out both;
}

@keyframes app-drawer-ai {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100vh);
  }
  10% {
    visibility: visible;
    opacity: 0;
    transform: translateY(100vh);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0vh);
  }
}

.app-drawer--inactive {
  animation: app-drawer-ao 1s 0s ease-in-out both;
}

@keyframes app-drawer-ao {
  0% {
    visibility: visible;
    opacity: 1;
    transform: translateY(0vh);
  }
  90% {
    visibility: visible;
    opacity: 0;
    transform: translateY(100vh);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateY(100vh);
  }
}

.app-drawer {
  z-index: 80;
  position: absolute;
}

.store-wrapper {
  overflow-y: scroll;
  height: 100vh;
  width: 100vw;
  padding-top: 0;
  position: relative;
  z-index: 70;
  background-color: #000000c5;
}

.store__title {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10rem;
  font-size: 6rem;
  font-weight: 900;
  color: white;
}

.store__subtitle {
  text-align: center;
  max-width: 30ch;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.app-grid {
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* sets stacking order for app-card__label::before */
  z-index: 1;
  padding: 10%;
  padding-top: 5rem;
  padding-bottom: 10rem;
}

@media only screen and (max-width: 1400px) {
  .app-grid {
    padding: 2%;
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
}

.app-grid > * {
  flex: 1 1 20em;
}

.app-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 14rem;
  min-width: 14rem;
  height: 20rem;
  background: white;
  border-radius: 12px;
  box-shadow: var(--shadow);
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  margin: 8rem 6rem 8rem 6rem;
  padding: var(--paddingSmall);
  padding-bottom: 1rem;
  transition: all 0.25s;
  cursor: pointer;
  position: relative;
}

.app-card:hover {
  transform: translateY(-2rem);
  z-index: 10;
}

.app-card:hover .app-card__label::before {
  opacity: 0;
}

.app-card__label::before {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  position: absolute;
  top: 26%;
  left: -41%;
  border-radius: 12px 12px 0px 0px;
  transform: rotateZ(-90deg);
  z-index: -1;
}

.app-card__label--graphic-design::before {
  background-color: #ff7051;
  color: white;
  content: "Graphic Design";
}

.app-card__label--music-prod::before {
  background-color: #9aff9e;
  content: "Music Prod.";
}

.app-card__label--photo-editing::before {
  background-color: #003f71;
  color: white;
  content: "Photo Editing";
}

.app-card__img {
  margin-bottom: 4rem;
  width: 10rem;
}

.app-card__price {
  font-size: 1.8rem;
  color: #b3b782;
}

.app-card__title {
  font-size: 2rem;
  color: var(--darkBlue);
}

.app-card__grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--paddingSmall);
  padding-bottom: 1rem;
}

.fade-in {
  animation: fade-in 1.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 
============================================================ 
Program Window/Slider
============================================================ 
*/
.program-window {
  position: absolute;
  overflow: hidden;
  z-index: 50;
  height: 100vh;
  width: 100vw;
  animation: program-window--animate 3s 6s both;
}

@keyframes program-window--animate {
  0% {
    visibility: hidden;
    clip-path: circle(0% at 50% 50%);
    opacity: 0;
  }
  80% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
    clip-path: circle(100% at 50% 50%);
  }
}

.slider-frame {
  overflow: hidden;
}

/* .splide__arrow {
  display: none;
} */

/* .toggle-btn__wrapper:hover .toggle-btn .splide__pagination__page {
  transform: translateY(10rem);
} */

/* .splide__pagination__page {
  height: 2rem !important;
  width: 10rem !important;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  z-index: 1000;
  transform: translateX(-35vw);
  display: none;
} */

/* .splide__pagination {
  margin-bottom: -2% !important;
} */

/* .splide__slide {
  background-color: #000;
} */

/* .splide__pagination__page.is-active {
  background-color: white !important;
}

.splide__pagination__page {
  background-color: #20b2aa !important;
} */

.program-frame {
  overflow: hidden !important;
  width: 100vw;
  height: 100vh;
}

.program-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
============================================================ 
Welcome Screen
============================================================ 
*/
.welcome-screen {
  background-color: #000;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 1000;
}

.welcome-screen__wrapper {
  animation: welcome-screen-a 2s 0.3s both;
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

@keyframes welcome-screen-a {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.welcome-screen__title {
  margin-top: 15%;
  color: white;
  margin-bottom: 12rem;
}

.program-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
  position: relative;
  margin-left: -4rem;
}

.program-picker--active {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 10;
  margin-bottom: -2px;
}

.program-picker__btn {
  border: none;
  background-color: #4650ac;
  color: white;
  height: 8rem;
  min-width: 28rem;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.program-picker__btn-text {
  font-size: 3rem;
  margin-right: auto;
  padding-left: 4rem;
}

.program-picker__caret-btn {
  transition: all 0.3s ease-out;
}

.program-picker__drop:hover .program-picker__caret-btn {
  transform: translateY(3px);
}

.program-picker__drop {
  position: absolute;
  min-width: 5rem;
  width: 7rem;
  left: 100%;
  top: 0%;
}

.program-picker__caret-btn {
  width: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.program-picker__drop-img {
  width: 4rem;
  padding-left: 3rem;
}

.program-picker__btn {
  transition: all 0.3s ease-out;
  margin-top: -2px;
}

.program-picker__btn:hover {
  background-color: #8d8537;
}

.program-picker__drop:hover {
  background-color: #313a8a;
}

.program-picker__menu--active {
  animation: program-picker__menu-ai 0.5s 0s both;
}

.program-picker__menu--inactive {
  animation: program-picker__menu-ao 0.3s 0s both;
}

@keyframes program-picker__menu-ai {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-6rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

@keyframes program-picker__menu-ao {
  from {
    opacity: 1;
    transform: translateY(0rem);
  }
  to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-6rem);
  }
}

/* 
============================================================ 
Program Loader Animation 
============================================================ 
*/
.loader {
  color: #ffffff;
  font-size: 80px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s ease;
  animation: loader-a 1.7s infinite ease, round 1.7s ease;
  animation-iteration-count: 8;
}

@media only screen and (max-width: 1400px) {
  .loader {
    font-size: 60px;
  }
}

@keyframes loader-a {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* 
============================================================ 
Individual App Pages 
============================================================ 
*/
.app-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "app-page__header"
    "app-page__body";
  width: 100%;
  height: 100%;
}

.app-page__header {
  grid-area: app-page__header;
  background-image: url("./media/AppStore/inkscapeDemo.png");
  background-size: contain;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3); /* uses a huge shadow to create overlay effect */
}

.app-page__card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 55%;
  height: 70vh;
  color: white;
  background-color: #333;
  border-radius: 0px 12px 12px 0px;
  box-shadow: 0px 4px 4px 0px #00000059;
  position: relative;
}

.app-card__title-section {
  width: 40%;
  margin-left: 10rem;
}

.app-card__logo-section {
  text-align: center;
}

.app-card__logo {
  width: 100%;
}

.app-page__title {
  margin-bottom: -2rem;
}

.app-card__back-arrow {
  top: 10%;
  left: 2%;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  position: fixed;
}

.app-card__back-arrow:hover {
  opacity: 0.6;
}

.app-page__btn {
  margin-top: 5rem;
  border-radius: 0;
  font-weight: 100;
}

.app-page__body {
  grid-area: app-page__body;
  background-color: #333;
  color: white;
  margin-top: -6rem;
  padding: 10rem 14rem;
}

.app-page__body-container {
  background-color: #333;
  display: flex;
  flex-direction: row;
}

.app-page__body-container div {
  width: 50%;
}

.app-body__heading {
  padding-top: 5rem;
}

.label-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.label {
  float: left;
  background: #c65c8280;
  border-radius: 71px;
  min-width: 180px;
  height: 30px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5rem;
  margin-bottom: 5rem;
}

.label p {
  display: block;
  text-align: center;
  cursor: default;
}

.label--secondary {
  background: #4077a94d;
}

.app-page__body-row {
  margin-top: 5rem;
}

.app-page__body-row--centered {
  margin-bottom: 10rem;
}

.borderGradient {
  border: 8px solid;
  border-image-slice: 1 !important;
  border-width: 8px;
}

.borderGradient--purple {
  border-image-source: linear-gradient(
    260.57deg,
    #6749be -2.67%,
    #fa7878 128.78%
  );
}
