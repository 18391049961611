.authForm {
  display: flex;
  justify-content: center;
}

.authForm__container {
  width: 40rem;
  height: auto;
  max-height: 60rem;
  margin-bottom: 10rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-top: 10rem;
  box-shadow: 3px 4px 8px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  padding: 3rem 4rem 3rem 4rem;
  border-width: 0 0 12px 0;
}

.authForm a {
  color: #6d72ec;
  text-decoration: underline;
}

.authForm__header {
  text-align: center;
  margin-bottom: 3rem;
}

.googleBtn {
  margin-bottom: 3rem;
}

.authForm__btn {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 6px 10px 6px 10px;
}

.authForm__submit {
  background-color: #e3562f;
  border: solid 2px #e3562f;
  color: white;
}

.authForm__submit:hover {
  background-color: #d64e28;
  border: solid 2px #e3562f;
}

.authForm__textBox {
  width: auto;
  margin-bottom: 3rem;
  padding-left: 15px;
  padding-right: 15px;
  height: 36px;
  font-size: 18px;
  background-color: #fff;
  border: #9b9b9b solid 1px;
  border-radius: 2px;
}

.authForm__textBox::placeholder {
  color: #b5b5b5;
  opacity: 1; /* Firefox */
}

.authForm__label {
  font-size: 18px;
  padding-bottom: 10px;
}

.authForm__footer {
  padding-top: 2rem;
}
