.advert {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 2rem;
}

@media only screen and (max-width: 1400px) {
  .advert {
    flex-wrap: wrap;
  }
  .reverse {
    flex-wrap: wrap-reverse;
  }
}

.advert__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 150ch;
}

.advert__img {
  display: flex;
  justify-content: center;
  margin: 5%;
  flex-basis: 100%;
}

.advert__img img {
  width: 100%;
  max-width: 80rem;
}

.vision {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* border: #000 solid 8px; */
  /* border-radius: 20px; */
  text-align: center;
  margin-top: 30rem;
  padding: 5rem;
  margin-bottom: 35rem;
  min-height: 25rem;
  max-width: 60vw;
  margin-left: auto;
  margin-right: auto;
}

.team {
  margin-top: 25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.team__title {
  max-width: 80ch;
  text-align: center;
  color: #ffffff;
}

.team__wrapper {
  margin-top: 5rem;
  /* border: #ffffff solid 8px; */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.team__descr {
  max-width: 50ch;
}

.team__img {
  width: 35%;
  min-width: 20rem;
  padding: 5rem;
  margin-bottom: auto;
  margin-top: -20rem;
}

.team__card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 20rem;
  position: relative;
}

.title-area {
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}

.title-area__title {
  color: var(--darkBlue);
}

.title-area__img {
  max-width: 30rem;
  min-width: 10rem;
  margin: 5rem;
}

.team__wrapper div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
