@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
.googleLogin {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 44px;
  margin-left: -4px;
  font-family: "Roboto", sans-serif;
}

.googleLogin__label {
  display: flex;
  width: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #4285f4;
  height: inherit;
  padding-left: 15px;
  margin-left: -5px;
}

.googleLogin__logo {
  height: 50px;
  width: auto;
}
