.nav__wrapper {
  position: relative;
  height: 6rem;
}

@media only screen and (max-width: 1200px) {
  .desktopMenu {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (min-width: 1200px) {
  .desktopMenu {
    display: block;
    visibility: visible;
  }
}

nav {
  background-color: white;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.8rem 0rem 0.8rem 0rem;
}

.nav__linkContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60vw;
}

.nav li {
  display: inline-block;
}

.nav__item a {
  text-decoration: none;
  list-style-type: none;
  color: var(--darkGray);
  font-family: "Arimo", sans-serif;
  font-size: 16px;
}

.nav__logo {
  max-width: 60px;
  height: auto;
  margin-left: -10rem;
}

.nav__links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav__caret {
  transform: translate(6px, 1px) rotateZ(-90deg);
  transform-origin: center;
  transition: all 0.3s ease-out;
  width: 14px;
}

.nav__item {
  width: auto;
  font-weight: 600;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: all 0.2s ease-out;
  color: #2d2d2d;
}

.nav__item a {
  cursor: pointer;
}

.nav__item--light {
  color: #2d2d2dd0;
}

.nav__item:hover {
  opacity: 0.7;
}

.nav__item:hover .nav__caret {
  transform: translate(10px, 1px) rotateZ(-90deg);
}

.nav__authContainer {
  display: flex;
  flex-direction: row;
  width: auto;
  margin-left: 10rem;
}

.nav__login {
  margin-right: 0;
}

.nav__signUp {
  color: #e3562f;
  text-decoration: underline;
}

.nav__signUp::before {
  content: "";
  margin-right: 2rem;
  width: 10px;
  border-left: solid 1px #2d2d2d86;
}

/* 
==========================================================
Mobile Menu 
==========================================================
*/

@media only screen and (max-width: 1200px) {
  .mobileMenu {
    display: block;
    visibility: visible;
  }
  .nav__authContainer {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .mobileMenu {
    display: none;
    visibility: hidden;
  }
}

.mobileMenu {
  background-color: white;
  height: 65px;
}

#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #333;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383f;
}
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100vh;
  box-shadow: 0 0 10px #85888c;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 90px;
  padding-bottom: 20px;
  background-color: #f5f6fa;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggle input:checked ~ ul {
  transform: none;
}
