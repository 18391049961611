.hero {
  display: grid;
  grid-template-columns: auto 25vw;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "welcome-container"
    "appScroller";
  justify-content: center;
  padding-top: 8vh;
  padding-bottom: 6rem;
  background-color: rgba(223, 223, 223, 0.1);
  background-image: url("./media/HomeP/Space-min.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 20px 40px;
}

.appScroller {
  margin-left: -8rem;
  height: 80vh;
  overflow-y: hidden;
}

.userGreeting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 6% 8rem 10% 8rem;
  margin-left: 5vw;
  margin-right: 5vw;
  background-color: white;
  box-shadow: -2px 6px 12px 2px #38394e50;
  border-width: 0 0 8px 8px;
  border-radius: 10px;
  max-height: 80vh;
}

@media only screen and (min-width: 1200px) {
  .userGreeting {
    min-width: 800px;
  }
}

.userGreeting__img {
  width: 100%;
  max-width: 50%;
  min-width: 300px;
}

.userGreeting__title {
  margin-right: 0;
}

.userGreeting__btn-container {
  margin-top: 5rem;
}

.btn-group {
  display: flex;
  flex-direction: row;
}

.btn-group :nth-child(1) {
  margin-right: 2rem;
}

@media only screen and (max-width: 1400px) {
  .hero {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "welcome-container"
      "appScroller";
    justify-content: center;
    align-items: center;
  }

  .appScroller {
    display: none;
  }
}

@media only screen and (max-width: 760px) {
  .userGreeting {
    padding-left: 20px;
    margin-top: -3rem;
    flex-direction: column;
  }

  .btn-group {
    flex-direction: column;
  }

  .btn-group :nth-child(1) {
    margin-bottom: 1rem;
  }
}
