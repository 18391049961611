.dashboard__wrapper {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background: url("../../media/User\ Auth/Background.svg");
  background-size: cover;
}

.dashboard {
  margin-top: 20vh;
}

.dashboard__btn {
  margin-top: 2rem;
}
